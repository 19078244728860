@mixin map-button {
  background-color: white;
  border: 2px solid white;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  cursor: pointer;
  
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;
  color: var(--pas-gray);

  transition: ease-in-out 0.15s color;

  &:hover {
		color: black;
  }
  
  &:focus, &:active:focus {
		outline: 0;
	}
}

.center-btn {
  @include map-button();
  margin-top: 0.6rem;
  margin-left: 0.6rem;
}

.right-btn {
  @include map-button();
  margin-top: 0.6rem;
  margin-right: 0.6rem;
}
.photo-gallery {
  height: auto;
  width: 100%;
  box-shadow: #e0e0e0 2px 2px 8px;
  display: flex;
  flex-direction: column;
   
  .photo-container {
    // height: calc(312rem / 16);
    width: 100%;

    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    cursor: pointer;
    transition: ease-in-out 0.15s opacity;
    &:active, &:not([data-no-hover]):hover {
      opacity: 0.8;
    }

    .photo-count {
      margin-bottom: 1rem;
      margin-right: 0.8rem;
      height: calc(24rem / 16);
      width: calc(56rem / 16);
      border-radius: 0.5rem;
      background-color: rgb(87, 87, 87);
      color: #ffffff;
      opacity: 0.6;
  
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
    }
  }
  
  .action-container {
    height: 2.125rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;


    .action-group {
      display: flex;
      align-items: center;
      flex-direction: row;
      // > * {
      //   margin-left: 4px;
      //   margin-right: 4px;
      // }
    }

    .btn {
      height: calc(34rem / 16);
      padding: 1px 12px 1px;
      border: 1px solid #F4F4F4;
      // border-radius: 0.5rem;
      color: #999898;
      font-size: 1rem;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      transition: ease-in-out 0.15s background-color;
      &:active, &:not(.no-hover):hover {
        background-color: rgba($color: #000000, $alpha: 0.2);
      }

      &.single{
        border-radius: 0.5rem;
      }

      &.left {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }

      &.right {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }

      // >* {
      //   padding-left: 1px;
      //   padding-right: 1px;
      // }

      &.page-turn {
        border-color: transparent;
        color: #1976d2;
        border-radius: 0.5rem;
      }
    }

    .rbtn {
      height: calc(34rem / 16);
      padding: 1px 12px 1px;
      border: 1px solid #F4F4F4;
      // border-radius: 0.5rem;
      color: #999898;
      font-size: 1rem;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &.page-turn {
        border-color: transparent;
        color: #1976d2;
        border-radius: 0.5rem;
      }
    }

  }
}

.toggle-btn-group{
  height: calc(34rem / 16);

}
$caption-default-font-size:  calc(13rem / 16);
$value-default-font-size:  calc(13rem / 16);

.outer {
  padding-top: 0.3rem;
  flex-wrap: wrap;
}

.root {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0.5rem;

  border: 1px solid #F4F4F4;
  background-color: #F4F4F4;
  border-radius: calc(5rem / 16);

  .value {
    font-size: $value-default-font-size;
    color: #2E2E2E;
  }

  .caption {
    position: absolute;
    left: 0.5rem;
    top: calc(#{$caption-default-font-size} * -1.5 / 2); // 1.5 * 0.625 / 2

    font-size: $caption-default-font-size;
    word-break: keep-all;

    color: #9A9A9A;
    white-space: nowrap;
  }

  .caption-bg {
    position: absolute;
    left: calc(#{$caption-default-font-size} / 2);
    width: 1.5rem;
    top: -1px;
    border-top: 1px solid #F4F4F4;
  }
}